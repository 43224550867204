import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  FormControl,
  Box,
  TextField,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../reduxToolkit/Notification/notificationSlice";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import Spinner from "../../utils/Spinner";
import {
  getProjectDetailsById,
  projectDetailsSave,
  projectDetailsUpdate,
} from "../../services/resumemaker-services";
import { InputTextfield } from "../theme";
import styles from "../../styles/signUp.module.css";

const schema = yup.object({
  projectName: yup.string().required("Project Name is required"),
  description: yup.string().required("Description is required"),
  techLeadName: yup.string().required("Tech Lead Name is required"),
  techLeadEmail: yup
    .string()
    .required("Tech Lead Email is required")
    .email("Invalid email"),
  accountManagerEmail: yup
    .string()
    .required("Account Manager Email is required")
    .email("Invalid email"),
  projectManagerEmail: yup
    .string()
    .required("Project Manager Email is required")
    .email("Invalid email"),
});

function ProjectDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [resourceEmails, setResourceEmails] = useState([""]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isEdit, id: updateId } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      projectName: "",
      description: "",
      techLeadName: "",
      techLeadEmail: "",
      accountManagerEmail: "",
      projectManagerEmail: "",
    },
  });

  useEffect(() => {
    if (isEdit === "true") {
      fetchProjectDetails();
    }
  }, [isEdit, updateId]);

  const fetchProjectDetails = async () => {
    setIsLoading(true);
    try {
      const res = await getProjectDetailsById(updateId, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      // Set form values
      reset({
        projectName: res.data.projectName,
        description: res.data.description,
        techLeadName: res.data.techLeadName,
        techLeadEmail: res.data.techLeadEmail,
        accountManagerEmail: res.data.accountManagerEmail,
        projectManagerEmail: res.data.projectManagerEmail,
      });

      // Set resource emails separately
      setResourceEmails(res.data.resourceEmails || [""]);
    } catch (error) {
      console.error("Failed to fetch project details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddField = () => {
    setResourceEmails((prev) => [...prev, ""]);
  };

  const handleRemoveField = (index) => {
    setResourceEmails((prev) => prev.filter((_, i) => i !== index));
  };

  const handleFieldChange = (index, value) => {
    setResourceEmails((prev) => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      // Filter out empty emails and combine form data with resource emails
      const filteredEmails = resourceEmails.filter(
        (email) => email.trim() !== ""
      );
      const submitData = {
        ...data,
        resourceEmails: filteredEmails,
      };

      if (isEdit === "true") {
        await projectDetailsUpdate(updateId, submitData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        dispatch(setMultiNotificationVariant("success"));
        dispatch(
          setMultiNotificationData([
            { propertyValue: "Project details updated successfully." },
          ])
        );
      } else {
        await projectDetailsSave(submitData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        dispatch(setMultiNotificationVariant("success"));
        dispatch(
          setMultiNotificationData([
            { propertyValue: "Project details saved successfully." },
          ])
        );
      }
      navigate("/resumemakerui/project-details-dashboard");
    } catch (error) {
      console.error("Error saving/updating project details:", error);
      dispatch(setMultiNotificationVariant("error"));
      dispatch(
        setMultiNotificationData([{ propertyValue: "Something went wrong." }])
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackClick = () =>
    navigate("/resumemakerui/project-details-dashboard");

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Box
              sx={{
                width: "100%",
                margin: "auto",
                padding: "50px",
                marginTop: "50px",
                boxShadow:
                  "rgba(14, 30, 37, 0.12) 0px 2px 4px, rgba(14, 30, 37, 0.32) 0px 2px 16px",
              }}
            >
              <h3>
                {isEdit === "true"
                  ? "Update Project Details"
                  : "Add Project Details"}
              </h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl sx={{ py: 1 }}>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                      <InputTextfield
                        fullWidth
                        label="Project Name"
                        {...register("projectName")}
                      />
                      {errors.projectName && (
                        <p className={styles.error}>
                          {errors.projectName.message}
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <InputTextfield
                        fullWidth
                        label="Description"
                        {...register("description")}
                      />
                      {errors.description && (
                        <p className={styles.error}>
                          {errors.description.message}
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <InputTextfield
                        fullWidth
                        label="Tech Lead Name"
                        {...register("techLeadName")}
                      />
                      {errors.techLeadName && (
                        <p className={styles.error}>
                          {errors.techLeadName.message}
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <InputTextfield
                        fullWidth
                        label="Tech Lead Email"
                        {...register("techLeadEmail")}
                      />
                      {errors.techLeadEmail && (
                        <p className={styles.error}>
                          {errors.techLeadEmail.message}
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <InputTextfield
                        fullWidth
                        label="Account Manager Email"
                        {...register("accountManagerEmail")}
                      />
                      {errors.accountManagerEmail && (
                        <p className={styles.error}>
                          {errors.accountManagerEmail.message}
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <InputTextfield
                        fullWidth
                        label="Project Manager Email"
                        {...register("projectManagerEmail")}
                      />
                      {errors.projectManagerEmail && (
                        <p className={styles.error}>
                          {errors.projectManagerEmail.message}
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <h4>Resource Emails</h4>
                      <List>
                        {resourceEmails.map((email, index) => (
                          <ListItem key={index}>
                            <TextField
                              fullWidth
                              value={email}
                              onChange={(e) =>
                                handleFieldChange(index, e.target.value)
                              }
                            />
                            <IconButton
                              onClick={() => handleRemoveField(index)}
                              disabled={resourceEmails.length === 1}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </ListItem>
                        ))}
                        <Button
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={handleAddField}
                        >
                          Add
                        </Button>
                      </List>
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", gap: 2 }}>
                      <Button type="submit" variant="contained" color="primary">
                        {isEdit === "true" ? "Update" : "Save"}
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleBackClick}
                      >
                        <ArrowBackOutlinedIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </FormControl>
              </form>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default ProjectDetails;
