import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Grid,
  FormControl,
  Box,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  List,
  ListItem,
  IconButton,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../reduxToolkit/Notification/notificationSlice";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "react-quill/dist/quill.snow.css";
import Spinner from "../../utils/Spinner";
import {
  getProjectDetails,
  getProjectDetailsForAdmin,
  getProjectUpdates,
  getProjectUpdatesById,
  getProjectUpdatesForAdmin,
  projectUpdatesSave,
  projectUpdatesUpdate,
} from "../../services/resumemaker-services";
import ReactQuill from "react-quill";

const schema = yup.object({
  projectName: yup.string().required("Project Name is required"),
  status: yup.string().required("Status is required"),
});

function ProjectUpdates() {
  const [isLoading, setIsLoading] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [generalUpdate, setGeneralUpdate] = useState([]);
  const [projectUpdatesData, setProjectUpdatesData] = useState([]);
  const [highLights, setHighLights] = useState([]);
  const [lowLights, setLowLights] = useState([]);
  const [editorState, setEditorState] = useState({
    isOpen: false,
    currentValue: "",
    currentIndex: null,
    currentField: null,
    currentSetter: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const ROLES = {
    ADMIN: "ADMIN",
    INTERNAL: "INTERNAL",
  };

  const { isEdit, id } = useParams();

  const formatStatus = (status) => {
    if (!status) return "";
    const normalizedStatus = status.toLowerCase();
    if (normalizedStatus === "green") return "Green";
    if (normalizedStatus === "red") return "Red";
    if (normalizedStatus === "orange") return "Orange";
    return status;
  };

  const fetchProjectDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getProjectUpdatesById(id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      const data = res.data;
      setValue("projectName", data?.projectDetails?.id || "");
      setValue("status", formatStatus(data?.status));
      setGeneralUpdate(data?.generalUpdate || []);
      setHighLights(data?.highLights || []);
      setLowLights(data?.lowLights || []);
    } catch (error) {
      console.error("Failed to fetch project details:", error);
    } finally {
      setIsLoading(false);
    }
  }, [id, setValue]);

  async function fetchUpdatesData() {
    if (
      localStorage.getItem("role") === "ADMIN" ||
      localStorage.getItem("role") === "INTERNAL"
    ) {
      const res = await getProjectUpdatesForAdmin({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setProjectUpdatesData(res.data || []);
    } else {
      const res = await getProjectUpdates(localStorage.getItem("email"), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setProjectUpdatesData(res.data || []);
    }
  }
  useEffect(() => {
    fetchUpdatesData();
  }, []);

  useEffect(() => {
    if (isEdit === "true") {
      fetchProjectDetails();
    }
  }, [isEdit, id, fetchProjectDetails]);

  const fetchData = async () => {
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return role === ROLES.ADMIN || role === ROLES.INTERNAL
      ? await getProjectDetailsForAdmin({ headers })
      : await getProjectDetails(email, { headers });
  };

  useEffect(() => {
    const emailres = localStorage.getItem("email");
    const role = localStorage.getItem("role");
    const isEditMode = isEdit === "true";

    const fetchDataAndFilter = async () => {
      try {
        const res = await fetchData();
        const filteredProjects = res.data.filter((project) => {
          return isEditMode && role === "ADMIN"
            ? true
            : project.techLeadEmail === emailres;
        });
        setProjectData(filteredProjects);
      } catch (error) {
        console.error("Failed to fetch and filter project data:", error);
      }
    };

    fetchDataAndFilter();
  }, [isEdit]);

  const handleAddField = (field, setField) => {
    setField((prev) => [...prev, ""]);
  };

  const handleRemoveField = (index, field, setField) => {
    setField((prev) => prev.filter((_, i) => i !== index));
  };

  const handleFieldChange = (index, value, field, setField) => {
    setField((prev) => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  };

  const isRecentUpdate = (updateData) => {
    const filteredProjects = projectUpdatesData.map(
      (project) => project.projectDetails
    );

    const project = filteredProjects.find(
      (project) => project.id === updateData.projectId
    );

    if (project && project.createdAt) {
      const projectDate = new Date(project.createdAt).toDateString();
      const todayDate = new Date().toDateString();

      return projectDate === todayDate;
    }

    return false;
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const updateData = {
      projectId: data.projectName,
      status: data.status.toUpperCase(),
      generalUpdate,
      highLights,
      lowLights,
    };

    const isResUpdate = isRecentUpdate(updateData);
    if (isResUpdate) {
      updateData.generalUpdate = [...updateData.generalUpdate];
      updateData.highLights = [...updateData.highLights];
      updateData.lowLights = [...updateData.lowLights];
    }

    try {
      const response =
        isEdit === "true" || isResUpdate
          ? await projectUpdatesUpdate(id, updateData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            })
          : await projectUpdatesSave(
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  "Content-Type": "application/json",
                },
              },
              updateData
            );

      dispatch(setMultiNotificationData(response.message));
      dispatch(setMultiNotificationVariant("success"));
      navigate("/resumemakerui/project-update-dashboard");
    } catch (error) {
      dispatch(setMultiNotificationData(error.message));
      dispatch(setMultiNotificationVariant("error"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditorOpen = (value, index, field, setter) => {
    setEditorState({
      isOpen: true,
      currentValue: value,
      currentIndex: index,
      currentField: field,
      currentSetter: setter,
    });
  };

  const handleEditorClose = () => {
    if (
      !editorState.currentValue ||
      editorState.currentValue.trim().toLowerCase() === "<p><br></p>"
    ) {
      handleRemoveField(
        editorState.currentIndex,
        editorState.currentField,
        editorState.currentSetter
      );
    }
    setEditorState({
      isOpen: false,
      currentValue: "",
      currentIndex: null,
      currentField: null,
      currentSetter: null,
    });
  };

  const handleEditorSave = () => {
    const { currentIndex, currentValue, currentField, currentSetter } =
      editorState;
    if (currentSetter && typeof currentIndex === "number") {
      currentSetter((prev) => {
        const updated = [...prev];
        updated[currentIndex] = currentValue;
        return updated;
      });
    }
    handleEditorClose();
  };

  const renderEditor = () => (
    <Dialog
      open={editorState.isOpen}
      onClose={handleEditorClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Edit Content</DialogTitle>
      <DialogContent>
        <div style={{ height: "400px", marginTop: "20px" }}>
          <ReactQuill
            value={editorState.currentValue}
            onChange={(value) =>
              setEditorState((prev) => ({ ...prev, currentValue: value }))
            }
            style={{ height: "300px" }}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: [] }],
                ["bold", "italic", "underline", "strike"],
                ["link"],
                [{ color: [] }, { background: [] }],
                ["clean"],
              ],
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleEditorClose}>Cancel</Button>
        <Button onClick={handleEditorSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const handleBackClick = () =>
    navigate("/resumemakerui/project-update-dashboard");

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Box
              sx={{
                width: "100%",
                margin: "auto",
                padding: "50px",
                marginTop: "50px",
                boxShadow:
                  "rgba(14, 30, 37, 0.12) 0px 2px 4px, rgba(14, 30, 37, 0.32) 0px 2px 16px",
              }}
            >
              <h3>
                {isEdit === "true"
                  ? "Edit Project Updates"
                  : "Add Project Update"}
              </h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="project-select-label">
                        Project Name *
                      </InputLabel>
                      <Controller
                        name="projectName"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              fullWidth
                              label="Project Name"
                              labelId="project-select-label"
                              value={field.value || ""}
                            >
                              {projectData?.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.projectName}
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      />
                      {errors.projectName && (
                        <p style={{ color: "red" }}>
                          {errors.projectName.message}
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="status-select-label">Status *</InputLabel>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Status"
                            labelId="status-select-label"
                          >
                            <MenuItem value="Green" style={{ color: "green" }}>
                              Green
                            </MenuItem>
                            <MenuItem value="Red" style={{ color: "red" }}>
                              Red
                            </MenuItem>
                            <MenuItem
                              value="Orange"
                              style={{ color: "orange" }}
                            >
                              Orange
                            </MenuItem>
                          </Select>
                        )}
                      />
                      {errors.status && (
                        <p style={{ color: "red" }}>{errors.status.message}</p>
                      )}
                    </FormControl>
                  </Grid>

                  {[
                    {
                      label: "General Updates",
                      field: generalUpdate,
                      setter: setGeneralUpdate,
                    },
                    {
                      label: "Highlights",
                      field: highLights,
                      setter: setHighLights,
                    },
                    {
                      label: "Lowlights",
                      field: lowLights,
                      setter: setLowLights,
                    },
                  ].map(({ label, field, setter }) => (
                    <Grid
                      item
                      xs={12}
                      key={label}
                      style={{ marginBottom: "16px" }}
                    >
                      <Card style={{ padding: "16px", margin: "8px 0" }}>
                        <CardContent>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "16px",
                              padding: "8px",
                            }}
                          >
                            <Typography variant="h5" style={{ margin: 0 }}>
                              {label}
                            </Typography>
                            <Button
                              startIcon={<AddCircleOutlineIcon />}
                              onClick={() => {
                                handleAddField(field, setter);
                                handleEditorOpen(
                                  field[field.length],
                                  field.length,
                                  field,
                                  setter
                                );
                              }}
                            >
                              Add
                            </Button>
                          </div>
                          <List>
                            {field.map((item, index) => (
                              <ListItem
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  marginBottom: "8px",
                                  padding: "8px",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "8px",
                                }}
                              >
                                <div
                                  style={{ marginBottom: "8px", width: "100%" }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  {item && (
                                    <Button
                                      variant="outlined"
                                      onClick={() =>
                                        handleEditorOpen(
                                          item,
                                          index,
                                          field,
                                          setter
                                        )
                                      }
                                    >
                                      Edit
                                    </Button>
                                  )}
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() =>
                                      handleRemoveField(index, field, setter)
                                    }
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </ListItem>
                            ))}
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                  <Grid item xs={12} sx={{ display: "flex", gap: 2 }}>
                    <Button type="submit" variant="contained" color="primary">
                      {isEdit === "true" ? "Update" : "Save"}
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<ArrowBackOutlinedIcon />}
                      onClick={handleBackClick}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
          {renderEditor()}
        </Grid>
      )}
    </div>
  );
}

export default ProjectUpdates;
