import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { uploadExcelforProjectDetails } from "../../services/resumemaker-services";
import {
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../reduxToolkit/Notification/notificationSlice";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  Input,
} from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";

const ExcelUpload = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile?.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
      setError("");
    } else {
      setError("Please select a valid .xlsx file");
      setFile(null);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError("Please select a file first");
      return;
    }

    setLoading(true);
    setError("");

    const formData = new FormData();
    formData.append("file", file);

    try {
      await uploadExcelforProjectDetails(formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(setMultiNotificationVariant("success"));
      dispatch(
        setMultiNotificationData([
          { propertyValue: "Project details saved successfully." },
        ])
      );
    } catch (err) {
      setError(err.message || "Failed to upload file");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 6, maxWidth: "800px", mx: "auto" }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Daily Updates Excel Upload
      </Typography>

      <Box
        sx={{
          border: "2px dashed gray",
          borderRadius: "8px",
          p: 4,
          textAlign: "center",
        }}
      >
        <Input
          type="file"
          accept=".xlsx"
          onChange={handleFileChange}
          sx={{ display: "none" }}
          id="file-upload"
        />
        <label htmlFor="file-upload">
          <IconButton component="span">
            <CloudUploadIcon sx={{ fontSize: 48, color: "gray" }} />
          </IconButton>
        </label>
        <Typography variant="body1" color="textSecondary">
          {file ? file.name : "Drop your Excel file here or click to browse"}
        </Typography>

        {file && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={24} /> : null}
            sx={{ mt: 2 }}
          >
            {loading ? "Uploading..." : "Upload File"}
          </Button>
        )}

        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ExcelUpload;
