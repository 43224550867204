import { Grid, TextField, Autocomplete } from "@mui/material";
import React from "react";

function SkillSets({ skillsets, setSkillsets, skills }) {
  const handlePaste = (e, skillKey) => {
    const pastedValues = e.clipboardData
      .getData("text/plain")
      .split(",")
      .map((value) => value.trim().toLowerCase());

    const filteredValues = pastedValues
      .filter((value) =>
        skills.map((option) => option.skillName.toLowerCase()).includes(value)
      )
      .map((value) => value.charAt(0).toUpperCase() + value.slice(1));

    setSkillsets((prev) => ({
      ...prev,
      [skillKey]:
        Array.isArray(prev[skillKey]) && prev[skillKey].length > 0
          ? prev[skillKey].concat(filteredValues)
          : filteredValues,
    }));
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <h2
            style={{
              border: "0.1px solid #239ce2",
              backgroundColor: "rgb(33, 80, 162)",
              textAlign: "center",
              color: "#fff",
            }}
          >
            Skill Set
          </h2>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
        className="subContainer"
      >
        {[
          { label: "Technologies", key: "technologies" },
          { label: "Languages", key: "languages" },
          { label: "Tools", key: "tools" },
          { label: "Databases", key: "databaseUsed" },
          { label: "Operating Systems", key: "operatingSystems" },
          { label: "IDEs", key: "ideUsed" },
          { label: "Web Servers", key: "webServer" },
        ].map(({ label, key }) => {
          return (
            <Grid container spacing={2} key={key}>
              <Grid item xs={12} sm={3}>
                <h3 style={{ margin: "5px" }}>{label}:</h3>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  multiple
                  options={
                    Array.isArray(skills)
                      ? [...new Set(skills.map((option) => option.skillName))]
                      : []
                  }
                  getOptionLabel={(option) => option || ""}
                  value={Array.isArray(skillsets[key]) ? skillsets[key] : []}
                  onChange={(event, newValue) => {
                    setSkillsets((prev) => ({ ...prev, [key]: newValue }));
                  }}
                  onPaste={(e) => handlePaste(e, key)}
                  renderInput={(params) => (
                    <TextField {...params} label={`${label}`} />
                  )}
                  clearOnEscape
                />
              </Grid>
            </Grid>
          );
        })}
      </div>
    </>
  );
}

export default SkillSets;
